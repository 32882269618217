/**
 * Supers Javascript library
 * Currently has a few helpers and the better-menu(needs to be superified)
 **/

import BetterMenu from "./lib/better-menu";
import FormLabelToggler from "./lib/form-label-toggler";
import SuperElementWatcher from "./lib/super-element-watcher";

// Attach the scripts after the website is up and running
document.addEventListener("DOMContentLoaded", () => {
	const betterMenu = new BetterMenu();
	//const formLabelToggler = new FormLabelToggler();
	new SuperElementWatcher();
});
